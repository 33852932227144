import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      partnerId: -1
    },
    orders: [],
    relatedOrders: [],
    totalOrders: 0,
    partners: [],
    customers: [],
    courses: [],
    isLoading: true,
    viewsCount: [],
    isTermsAgree: {},
  },
  mutations: {
    setUser(state, json) {
      //If admin user or partner with access to multiple partners change the default Selection to All Partners
      if ( (json.content.user_role == 'admin' || json.content.user_role == 'uop_admin') ||
      ( json.content.user_role == 'partner' && json.content.partners && json.content.partners.length > 1) ) {
        json.content.partnerId = -1
      //Else change the default partner to the partner they have acccess to
      } else {
        json.content.partnerId = json.content.partners[0]
      }
      json.content.token = json.token;
      state.user = json.content;
      if (json.token) window.localStorage.token = json.token;
    },
    setPartners(state, json) {
      // console.log("SET PARTNERS CONTENT", json.content)
      // json.content = [json.content[0]]
      //If admin user or partner with access to multiple partners add the all Partners Option
      if ((state.user.user_role == 'admin' || state.user.user_role == 'uop_admin' || state.user.user_role == 'partner') &&
      (  json.content && json.content.length >= 1) ) {        
        if(json.content.length === 1){
          // update user default id incase there is only one partner
            state.user.partnerId = json.content[0].partner_id
        }else{
          json.content.unshift({ partner_id: -1, partner_name: 'All Partners' });
        }
      }
      state.partners = json.content;
    },
    resetOrdersAndCourses(state) {
      state.orders = [];
      state.courses = [];
    },
    setOrders(state, json) {
      state.orders = json.content;
      state.totalOrders = json.order_total_count;
    },
    setLoadingState(state, {isLoading}){
      state.isLoading =  isLoading
    },
    setRelatedOrders(state, json) {
      state.relatedOrders = json.content;
    },
    setCourses(state, json) {
      state.courses = json.content;
    },
    setCustomers(state, json) {
      state.customers = json.content;
    },
    setViewCounts(state, json){
      state.viewsCount = json.content
    },
    setIsAgreedTerms(state, json){
      state.isTermsAgree = json
    }
  },
  actions: {
    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json", "Accept": "application/json" },
          body: JSON.stringify({ user_login: payload.user_login, pass: payload.pass, agreed_terms: payload.agreed_terms })
        };

        fetch(`${process.env.VUE_APP_API_URL}/users/login`, requestOptions).then(res => {
          res.json().then(json => {
            if (json.status === 'success') {
              commit('setUser', json);
              resolve();
            } else {
              reject(json.message);
            }
          })
        }).catch(() => {
          // console.log('err', err);
          reject();
        })
      })
    },
    logout() {
      window.localStorage.removeItem('token');
      // Hard redirect to reset all states
      window.location.href = "/";
    },
    refreshUser({ commit }) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_API_URL}/users/profile`;
        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': window.localStorage.token
          }
        }).then(res => {
          if (res.status === 401) return reject({ success: false, payload: 'Unauthorized' });
          res.json().then(json => {
            if (json.status === 'success') {
              commit('setUser', json);
              resolve({ success: true, payload: json.content });
            } else {
              reject(json.message);
            }
          });
        }).catch(err => {
          reject(err);
        })
      })
    },
    fetchPartners({ commit, state, getters }) {
      return new Promise((resolve, reject) => {
        //If user role is uop_admin reject 
        if(state.user.user_role == 'uop_admin') {
          commit('setPartners', {content: [{ partner_id: -1, partner_name: 'All Partners' }]});
          return resolve('One partner set for UOP Admin')
        }
        //If user role is partner and don't have access to more than one partner reject
        if(state.user.user_role == 'partner') {
          if(! state.user.partners ) return reject('Partners unavailable')
          if(! state.user.partners.length > 1)  return reject('Partners unavailable')
        }
        const endpoint = (state.user.user_role == 'partner') ? 
          `${process.env.VUE_APP_API_URL}/partners/user/${state.user._id}` : `${process.env.VUE_APP_API_URL}/partners`
       
        //Fetch the partners given the endpoint
        fetch(endpoint, getters.AuthHeader).then(res => {
          if (res.status === 200) {
            res.json().then(json => {
              commit('setPartners', json);
              resolve({ success: true, payload: json });
            }).catch(err => {
              reject({ success: false, payload: err });
            })
          } else {
            res.json().then(json => {
              reject({ success: false, payload: json.message });
            }).catch(err => {
              reject({ success: false, payload: err });
            });
          }
        }).catch(err => {
          reject({ success: false, payload: err });
        })
      })
    },
    fetchOrders({ commit, state, getters }, queryString) {
      return new Promise((resolve, reject) => {
        commit('resetOrdersAndCourses');
        commit('setLoadingState', {isLoading: true})
        if (!state.user.user_role || !queryString) return reject('Orders unavailable');
        if (state.user.partnerId && state.user.partnerId !== -1) queryString += `&partner_id=${state.user.partnerId}`;
        if(state.user.partners && state.user.user_role == "partner" && state.user.partnerId == -1) {
          queryString += `&partners=${JSON.stringify(state.user.partners)}`;
        }
        const url = `${process.env.VUE_APP_API_URL}/orders?${queryString}`;
        fetch(url, getters.AuthHeader).then(res => {
          res.json().then(json => {
            commit('setLoadingState', {isLoading: false})
            if (json.status === 'success') {
              commit('setOrders', json);
              resolve({ success: true, payload: json });
            }
            else reject({ success: false, payload: json.message });
          })
        }).catch(err => {
          commit('setLoadingState',{isLoading: false})
          reject({ success: false, payload: err.message });
        })
      });
    },
    fetchRelatedOrders({ commit, state, getters }, customerID) {
      return new Promise((resolve, reject) => {
        var url = `${process.env.VUE_APP_API_URL}/orders?customer=${customerID}`;
        if (state.user.user_role == 'partner') {
          url += `&partners=${JSON.stringify(state.user.partners)}`;
        }
        fetch(url, getters.AuthHeader).then(res => {
          if (res.status === 200) {
            res.json().then(json => {
              if (json.status === 'success') {
                commit('setRelatedOrders', json, customerID);
                resolve({ success: true, payload: json });
              } else {
                reject({ success: false, payload: json.message });
              }

            }).catch(err => {
              reject({ success: false, payload: err });
            })
          } else {
            res.json().then(json => {
              reject({ success: false, payload: json.message });
            }).catch(err => {
              reject({ success: false, payload: err });
            });
          }
        }).catch(err => {
          reject({ success: false, payload: err.message });
        })
      });
    },
    fetchCourses({ commit, getters }, partner_id) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_API_URL}/courses/${partner_id}`;
        fetch(url, getters.AuthHeader).then(res => {
          if (res.status === 200) {
            res.json().then(json => {
              commit('setCourses', json, partner_id);
              resolve({ success: true, payload: json });
            }).catch(err => {
              reject({ success: false, payload: err });
            })
          } else {
            res.json().then(json => {
              reject({ success: false, payload: json.message });
            }).catch(err => {
              reject({ success: false, payload: err });
            });
          }
        }).catch(err => {
          reject({ success: false, payload: err });
        });
      });
    },
    fetchCustomers({ commit, state, getters }) {
      return new Promise((resolve, reject) => {
        if (!state.user.user_role) return reject('Customers unavailable');
        //If partner create the string to add to the endpoint
        if( state.user.user_role === "partner") { 
          var addString = (state.user.partnerId === -1 && state.user.partners) ? 
          `?partners=${JSON.stringify(state.user.partners)}` : `/${state.user.partnerId}`;
        }
        const url = `${process.env.VUE_APP_API_URL}/customers${addString ?? ''}`;
        fetch(url, getters.AuthHeader).then(res => {
          console.log('students responded')
          if (res.status === 200) {
            res.json().then(json => {
              commit('setCustomers', json);
              resolve({ success: true, payload: json });
            }).catch(err => {
              reject({ success: false, payload: err });
            })
          } else {
            res.json().then(json => {
              reject({ success: false, payload: json.message });
            }).catch(err => {
              reject({ success: false, payload: err });
            });
          }
        }).catch(err => {
          reject({ success: false, payload: err });
        })
      })
    },
    deleteOrder({ getters }, orderId) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_API_URL}/orders/${orderId}`;
        const params = { method: 'DELETE', ...getters.AuthHeader };
        fetch(url, params).then(res => {
          res.json().then(json => {
            if (json.status === 'success') resolve({ success: true, payload: json });
            else reject({ success: false, payload: json });
          }).catch(err => {
            reject(err);
          });
        }).catch(err => {
          reject({ success: false, payload: err });
        })
      });
    },
    updateOrder({ getters }, updateObj) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_API_URL}/orders/${updateObj._id}`;
        delete updateObj._id;
        fetch(url, {
          method: 'PUT',
          body: JSON.stringify(updateObj),
          headers: {
            'Content-Type': 'application/json',
            ...getters.AuthHeader.headers
          }
        }).then(res => {
          res.json().then(json => {
            if (json.status === 'success') resolve({ success: true, payload: json });
            else reject({ success: false, payload: json.message });
          }).catch(err => {
            reject(err);
          })
        }).catch(err => {
          reject({ success: false, payload: err });
        })
      })
    },
    sendPutRequest({ getters }, payload) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_API_URL}/${payload.endpointPath}`;
        fetch(url, {
          method: 'PUT',
          body: payload.body ? JSON.stringify( payload.body ) : JSON.stringify({}),
          headers: {
            'Content-Type': 'application/json',
            ...getters.AuthHeader.headers
          }
        }).then(res => {
          //if (res.status === 200) {
            res.json().then(json => {
              if (json.status && json.status === 'success') resolve({ success: true, payload: json });
              else reject({ success: false, payload: json.message });
            });
          //} else {
            //reject({ success: false, payload: json.message });
         // }
        }).catch(err => {
          reject({ success: false, payload: err });
        })
      })
    },
    deleteAdminNote({ getters }, payload) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_API_URL}/orders/${payload._id}/adminNote`;
        fetch(url, {
          method: 'DELETE',
          body: JSON.stringify({ noteObjectId: payload.noteObjectId }),
          headers: {
            'Content-Type': 'application/json',
            ...getters.AuthHeader.headers
          }
        }).then(res => {
          if (res.status === 200) {
            res.json().then(json => {
              if (json.status === 'success') resolve({ success: true, payload: json });
              else reject({ success: false, payload: json.message });
            });
          } else {
            reject({ success: false, payload: res });
          }
        }).catch(err => {
          reject({ success: false, payload: err });
        })
      })
    },
    sendMessage({ getters }, payload) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_API_URL}/orders/${payload._id}/sendMessage`;
        fetch(url, {
          method: 'POST',
          body: payload.data,
          headers: {
            ...getters.AuthHeader.headers
          }
        }).then(res => {
          if (res.status === 200) {
            res.json().then(json => {
              if (json.status === 'success') resolve({ success: true, payload: json });
              else reject({ success: false, payload: json.message });
            });
          } else {
            reject({ success: false, payload: res });
          }
        }).catch(err => {
          reject({ success: false, payload: err });
        })
      })
    },
    bannerGrade({ getters }, orderId) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_API_URL}/orders/${orderId}/banner_grade`;
        const params = { method: 'PUT', ...getters.AuthHeader };
        fetch(url, params).then(res => {
          res.json().then(json => {
            if (json.status === 'success') resolve({ success: true, message: json.message });
            else reject({ success: false, payload: json.message });
          })
        }).catch(err => {
          reject({ success: false, payload: err });
        })
      })
    },
    refundOrder({ getters }, orderId) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_API_URL}/orders/${orderId}/refund`;
        const params = { method: 'PUT', ...getters.AuthHeader };
        fetch(url, params).then(res => {
          res.json().then(json => {
            if (json.status === 'success') resolve({ success: true, message: json.message });
            else reject({ success: false, payload: json.message });
          })
        }).catch(err => {
          reject({ success: false, payload: err });
        })
      })
    },
    sendResubmitOrder({ getters }, orderId) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_API_URL}/orders/${orderId}/sendResubmit`;
        const params = { method: 'PUT', ...getters.AuthHeader };
        fetch(url, params).then(res => {
          res.json().then(json => {
            if (json.status === 'success') resolve({ success: true, message: json.message });
            else reject({ success: false, payload: json.message });
          })
        }).catch(err => {
          reject({ success: false, payload: err });
        })
      })
    },
    dateFilter({ commit, getters }, queryString) {
      commit('resetOrdersAndCourses');
      commit('setLoadingState', {isLoading: true})
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_API_URL}/orders/${queryString}`;
        const params = { method: 'GET', ...getters.AuthHeader };
        fetch(url, params).then(res => {
          res.json().then(json => {
            if (json.status === 'success') {
              commit('setOrders', json);
              resolve({ success: true, payload: json });
            }
            else reject({ success: false, payload: json.message });
          })
        }).catch(err => {
          reject({ success: false, payload: err });
        }).finally(() => {
          commit('setLoadingState', {isLoading: false})

        })
      })
    },
    getViewsCount({ commit }, data) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_API_URL}/orders/view_counts`;
        const params = { method: 'POST',
        headers: { "Content-Type": "application/json", "Accept": "application/json",
        "Authorization": window.localStorage.token
      },
        body: JSON.stringify(data)
       };
        fetch(url, params).then(res => {
          res.json().then(json => {
            if (json.status === 'success') {
              commit('setViewCounts', json);
              resolve({ success: true, payload: json });
            }
            else reject({ success: false, payload: json.message });
          })
        }).catch(err => {
          reject({ success: false, payload: err });
        })
      })
    },
    isAgreedTerms({ commit }, data) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_API_URL}/users/agreed_terms/`;
        const params = { method: 'POST',
        headers: { "Content-Type": "application/json", "Accept": "application/json",
      },
        body: JSON.stringify(data)
       };
        fetch(url, params).then(res => {
          res.json().then(json => {
            if (json.status === 'success') {
              commit('setIsAgreedTerms', json);
              resolve({ success: true, payload: json });
            }
            else reject({ success: false, payload: json.message });
          })
        }).catch(err => {
          reject({ success: false, payload: err });
        })
      })
    },
    downloadExport({ state}, queryString) {
        return new Promise((resolve, reject) => {
          if (!state.user.user_role || !queryString) return reject('Orders unavailable');
          if (state.user.partnerId && state.user.partnerId !== -1) queryString += `&partner_id=${state.user.partnerId}`;
          if(state.user.partners && state.user.user_role == "partner" && state.user.partnerId == -1) {
            queryString += `&partners=${JSON.stringify(state.user.partners)}`;
          }
          const params = { method: 'GET',
          headers: { "Content-Type": 'text/csv;charset=UTF-8',
          "Authorization": window.localStorage.token

        }
      }
          const url = `${process.env.VUE_APP_API_URL}/orders/download/csv?${queryString}`;          
          fetch(url, params).then(res => {
            res.blob().then(json => {
              if(res.status === 200){
                resolve({ success: true, payload: json });
              }else{
                reject({ success: false, payload: json });
              }
            })
          }).catch(err => {
            reject({ success: false, payload: err.message });
          })
        
        })
    }

  },
  modules: {
  },
  getters: {
    AuthHeader() { return { headers: { "Authorization": window.localStorage.token } } },
    orders(state) { return state.orders },
    isTermsAgree(state) {return state.isTermsAgree.agreed_terms},
    getAvatarInitial(state) {
      return (state.user && state.user.name) ? state.user.name.charAt(0) : " ";
    },
  }
})
